import logo from './logo.svg';
// import './App.css';
import './styles/css/normalize.css';
import './styles/css/base-style.css';
import './styles/css/style.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


import { useEffect } from 'react';
import Landing from './pages/landing';
import Claims from './pages/claims';
import HealthInsurance from './pages/health-insurance';
// import IncomeProtection from './pages/income-protection';
import HealthCash from './pages/health-cash';
import Partners from './pages/partners';
import Business from './pages/business';
import CreditLife from './pages/credit-life';
import About from './pages/about';
import BusinessGro from './pages/business-gro';
import Insuretech from './pages/insuretech';
import Legal from './pages/legal';
import PrivacyPolicy from './pages/privacy-policy';
import Terms from './pages/terms';

function App() {
  
  useEffect(() => {
    document.body.addEventListener('click',function(evt) {
      if (!evt.target.matches('.menu-products-dropbtn')) {
      var navdropdowns = document.getElementsByClassName("menu-products-dropdown");
      var i;
      for (i = 0; i < navdropdowns.length; i++) {
        var openDropdown = navdropdowns[i];
        if (openDropdown.classList.contains('active')) {
          openDropdown.classList.remove('active');
        }
      }
    }
    })
  })

  useEffect(() => {
    document.body.addEventListener('click',function(evt) {
      if (!evt.target.matches('.menu-learn-dropbtn')) {
      var navdropdowns = document.getElementsByClassName("menu-learn-dropdown");
      var i;
      for (i = 0; i < navdropdowns.length; i++) {
        var openDropdown = navdropdowns[i];
        if (openDropdown.classList.contains('active')) {
          openDropdown.classList.remove('active');
        }
      }
    }
    })
  })

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/'  element={<Landing/>}/> 
          <Route exact path="/claims" element={<Claims/>}/> 
          <Route exact path="/health-insurance" element={<HealthInsurance/>}/> 
          <Route exact path="/insuretechconnect" element={<Insuretech/>}/> 
          {/* <Route exact path="/income-protection" element={<IncomeProtection/>}/>  */}
          <Route exact path="/health-cash" element={<HealthCash/>}/> 
          <Route exact path="/partners" element={<Partners/>}/> 
          <Route exact path="/business" element={<Business/>}/> 
          {/* <Route exact path="/legal" element={<Legal/>}/>  */}
          <Route exact path="/credit-life" element={<CreditLife/>}/> 
          <Route exact path="/business-gro" element={<BusinessGro/>}/> 
          <Route exact path="/about" element={<About/>}/> 
          <Route exact path="/privacy-policy" element={<PrivacyPolicy/>} /> 
          <Route exact path="/terms" element={<Terms/>} /> 
          <Route exact path='*'  element={<Landing/>}/> 
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
