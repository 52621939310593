
import business_gro_hero from '../../assets/businessgro.jpg';
function Cover() {
    return (
      <>
  
        {/* cover section  */}
        <section className="hero-sectionbiz">
        <div className="container flex flex-col justify-between md:items-center lg:flex-row">
            <div className="grid justify-center gap-10 grid-cols-2 md:grid-cols-1">
                <div>
                    <h1>
                        Grow your <br /> business with <br/> Business gro
                    </h1>
                    <p className="text-lg md:text-xl mr-0 md:mr-10">Enjoy easy access to business loans, business  <br/> insurance and free trainings</p>
                    <a href="https://smedanregister.ng/" className="button button--white mt-6"><span>Sign up</span></a>
                </div>
            </div>
            <div ><img src={business_gro_hero} alt="business_gro_hero" className='hero-image'/></div>
        </div>
    </section>
      </>
    );
  }
  
  export default Cover;
  