import casavaClock from '../../assets/casava-clock.mp4';

function GettingStarted() {
    return (
      <>
        {/* get started section  */}
        <section className="howto-section">
            <div className="container">
                <div className="grid grid-cols-2 gap-12 items-center lg:grid-cols-2 xl:grid-cols-2">
                    <div>
                        <h1 className="mb-6">
                            90 seconds <br />
                            to get a cover
                        </h1>
                        <p className="text-xl mr-0 lg:mr-12"> We took out long forms so you can sign up <br /> with ease and enjoy one-month free insurance.</p>
                        <a href="https://insurance.casava.co/signup/" className="button button--pink mt-6" aria-label="Get Started"><span>Get Started</span></a>
                    </div>
                    <div>
                        {/* <img src={casavaClock} alt="Get Started" width={450} /> */}
                        <video width="500" height="500" loop muted autoPlay className="hero-video">
                            <source src={casavaClock} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </section>
      </>
    ); 
  }
  
  export default GettingStarted;